/* istanbul ignore file */
import config from "config";
import { RequestOptions, Response } from "services/request";
import { requestController } from "services/request/requestController";
import { notifyError } from "store/actions/notifications";
import { authToken } from "utils/helpers";
import handleErrors from "./handleErrors";
import handleForbiddenAccess from "./handleForbiddenAccess";
import handleUnauthorizedAccess from "./handleUnauthorizedAccess";

export default async function requestCore<T>(url: string, optionsIn: RequestOptions = {}): Promise<Response<T>> {
  const sessionToken: string | null = authToken.get();
  const sessionSandbox: boolean = false;
  const headers = new Headers({
    "Trolley-Source": "merchant_dashboard",
  });

  if (!optionsIn.isUpload) {
    headers.set("Content-Type", "application/json");
  }

  const options: RequestOptions & { signal?: AbortSignal } = {
    signal: requestController.getSignal(),
    headers,
    ...optionsIn,
  };

  if (sessionToken && options.headers && options.noToken !== true) {
    options.headers.set("Authorization", `prtoken ${sessionToken}`);
  }

  try {
    const address = sessionSandbox && config.SANDBOX_ADDRESS ? `${config.SANDBOX_ADDRESS}${url}` : `${config.SERVER_ADDRESS}${url}`;

    const res = await fetch(address, options);
    if (res.status !== 200) {
      const errorBody = await res.json();

      if (errorBody && errorBody.ok === false && errorBody.errors) {
        throw { status: res.status, body: { ok: false, errors: errorBody.errors } };
      } else {
        throw res;
      }
    }

    const body = optionsIn.blob ? {} : await res.json();
    if (options.blob) {
      return {
        status: res.status,
        body,
        blob: await res.blob(),
      };
    }

    return {
      status: res.status,
      body,
    };
  } catch (error) {
    if (error?.name === "AbortError") {
      throw error;
    } else {
      if (error.body && typeof error.status === "number") {
        if (error.status === 401) {
          await handleUnauthorizedAccess(error);
        } else if (error.status === 403) {
          const responseWithTFA = await handleForbiddenAccess<T>(error, url, options);
          if (responseWithTFA) {
            return responseWithTFA;
          }
        }

        throw handleErrors(error);
      }

      notifyError("Network Error");
      throw new Error("Network Error");
    }
  }
}
